import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const FeatureSection = ({ title, desc, img, direction }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ delay: 900 });

  useEffect(() => {
    if (inView) {
      controls.start({ y: 0, opacity: 1 });
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      className="py-12 flex justify-center items-center gap-5 md:gap-20"
      whileHover={{ scale: 1.05 }}
      initial={{ y: 100, opacity: 0 }}
      animate={controls}
      transition={{
        duration: 1,
        type: "spring",
        stiffness: 100,
        damping: 10,
        mass: 1,
      }}
    >
      {direction === "r" ? (
        <>
          <div className="flex flex-col gap-5">
            <h2 className="text-2xl md:text-4xl font-semibold text-gray-600">
              {title}
            </h2>
            <p className="md:text-xl text-gray-600">{desc}</p>
          </div>
          <img className="w-1/3" src={img} alt="첫번쨰 사진" />
        </>
      ) : (
        <>
          <img className="w-1/3" src={img} alt="첫번쨰 사진" />
          <div className="flex flex-col gap-5">
            <h2 className="text-2xl md:text-4xl font-semibold text-gray-600">
              {title}
            </h2>
            <p className="md:text-xl text-gray-600">{desc}</p>
          </div>
        </>
      )}
    </motion.div>
  );
};

export default FeatureSection;
