import "./App.css";
import FAQSection from "./components/FAQSection";
import FeatureSection from "./components/FeatureSection";
import Header from "./components/Header";

import Img1 from "./assets/1.png";
import Img2 from "./assets/2.png";
import Img3 from "./assets/3.png";

function App() {
  return (
    <div className="pt-20 bg-gradient-to-r from-[#A8E6CF] to-[#FFD3B6]">
      <Header />

      <div className="flex flex-col gap-10">
        {[
          {
            title: "다양한 식물들",
            desc: (
              <>
                나의 식물을 커뮤니티와 공유하며,
                <br />
                다른 식물을 관찰하고 이해해 보세요!
              </>
            ),
            img: Img1,
          },

          {
            title: "내 식물 기록",
            desc: (
              <>
                식물로 만드는 소중한 추억,
                <br />
                함께 보존하고 나눌래요?
              </>
            ),
            img: Img2,
            direction: "r",
          },

          {
            title: "AI",
            desc: (
              <>
                AI가 여러분의 질문에 답하겠습니다!
                <br />
                최신 GPT가 이미지를 해석하고 식물에 대한 의문점을 제거해줄게요.
              </>
            ),
            img: Img3,
          },
        ].map((item) => (
          <FeatureSection
            title={item.title}
            desc={item.desc}
            img={item.img}
            direction={item.direction}
          />
        ))}
      </div>

      <FAQSection />

      <footer className="bg-black py-5 text-center text-sm text-gray-300">
        © Jung {new Date().getFullYear()}
      </footer>
    </div>
  );
}

export default App;
