import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import FAQItem from "./FAQItem";

const FAQSection = () => {
  const faqs = [
    {
      question: "Planet Diary에서는 어떤 식물 정보를 기록할 수 있나요?",
      answer:
        "사용자는 자신의 식물의 이름, 종류, 물주기, 성장 단계, 특별한 관리 요구 사항 등을 기록할 수 있습니다. 또한, 식물의 사진을 추가하여 성장 과정을 시각적으로 추적할 수도 있습니다.",
    },

    {
      question: "AI 식물 진단 기능은 어떻게 사용하나요?",
      answer:
        "식물의 사진을 앱에 업로드하면, AI가 식물의 현재 상태를 분석하여 건강 문제의 징후나 관리 방법에 대한 조언을 제공합니다. 이 기능은 식물에 대한 정확한 진단과 맞춤형 관리 팁을 제공하기 위해 고안되었습니다.",
    },
    {
      question: "커뮤니티 기능은 어떻게 활용할 수 있나요?",
      answer: (
        <>
          사용자들은 자신의 식물 일지를 공유하고, 다른 사용자의 일지를 읽으며
          소통할 수 있습니다. 또한 궁금한 점이 있다면 카페를 통해 커뮤니티를
          활용해보세요.
          <br />
          <br />
          카페 : https://cafe.naver.com/planetdiary
        </>
      ),
    },
  ];

  const controls = useAnimation();
  const [ref, inView] = useInView({ delay: 500 });

  useEffect(() => {
    if (inView) {
      controls.start({ y: 0, opacity: 1 });
    }
  }, [controls, inView]);

  return (
    <div ref={ref} className="flex flex-col gap-5 my-20 py-4 mx-5 md:mx-32">
      <h2 className="text-4xl font-bold text-gray-800 mb-5">FAQ</h2>
      {faqs.map((faq, index) => (
        <FAQItem key={index} {...faq} />
      ))}{" "}
    </div>
  );
};

export default FAQSection;
