// path/filename: src/components/FaqItem.js

import React, { useState } from "react";
import { motion } from "framer-motion";

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  const toggleVariants = {
    open: { rotate: 45, scale: 1.5 },
    closed: { rotate: 0, scale: 1 },
  };

  const answerVariants = {
    open: { opacity: 1, height: "auto" },
    closed: { opacity: 0, height: 0 },
  };

  return (
    <div className="cursor-pointer" onClick={toggleOpen}>
      <div className="flex gap-2">
        <motion.p
          className="faq-toggle w-3 text-bold"
          animate={isOpen ? "open" : "closed"}
          variants={toggleVariants}
          transition={{ duration: 0.5 }}
        >
          +
        </motion.p>
        {question}
      </div>
      <motion.div
        className="text-gray-500 mt-3"
        initial="closed"
        animate={isOpen ? "open" : "closed"}
        variants={answerVariants}
        transition={{ duration: 0.5 }}
      >
        {answer}
      </motion.div>
    </div>
  );
};

export default FAQItem;
