import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import MainImg from "../assets/main.png";
import GoogleStore from "../assets/google_store.png";
import AppleStore from "../assets/apple_store.svg";

const Header = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ delay: 500 });

  useEffect(() => {
    if (inView) {
      controls.start({ y: 0, opacity: 1 });
    }
  }, [controls, inView]);

  return (
    <motion.header
      ref={ref}
      className="flex flex-col justify-center items-center text-center py-4 my-20"
      initial={{ y: 100, opacity: 0 }}
      animate={controls}
      transition={{
        duration: 1,
        type: "spring",
        stiffness: 100,
        damping: 10,
        mass: 1,
      }}
    >
      <h1 className="text-5xl md:text-7xl font-bold text-gray-800">
        Planet Diary
      </h1>
      <img className="md:w-1/3 my-24" src={MainImg} alt="메인 이미지" />
      <span className="text-xl md:text-3xl text-gray-500">
        Planet Diary에서 내 식물을 관리하고
        <br />
        다른 식물에 대해서 알아보세요.
      </span>
      <div className="md:flex gap-32 mt-24">
        <a href="https://play.google.com/store/apps/details?id=com.jung.planet&pli=1">
          <img
            className="w-64"
            src={GoogleStore}
            alt="구글 스토어 링크 아이콘"
          />
        </a>
        <a
          className="flex"
          href="https://apps.apple.com/us/app/planet-diary/id6473107463"
        >
          <img
            className="w-64"
            src={AppleStore}
            alt="애플 스토어 링크 아이콘"
          />
        </a>
      </div>
    </motion.header>
  );
};

export default Header;
